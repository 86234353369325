<template>
  <v-navigation-drawer
    style="position: fixed; max-height: 100%"
    :value="menuStatus"
    :right="this.rtl"
    absolute
    temporary
    :color="this.primaryColor"
  >
    <v-list dense nav>
      <template v-for="(item, i) in isAdmin ? itemsForList : itemsForEditor">
        <v-list-item :key="i" link :to="{ name: item.to }">
          <v-list-item-icon>
            <v-icon color="white" class="py-2">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text py-2">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <!--        <v-list dense nav>-->
    <!--            <v-list-group-->
    <!--                    v-for="(item, i) in items"-->
    <!--                    :key="i"-->
    <!--                    v-model="item.active"-->
    <!--                    :prepend-icon="item.icon"-->
    <!--                    no-action-->
    <!--            >-->
    <!--                <template v-slot:activator>-->
    <!--                    <v-list-item-content>-->
    <!--                        <v-list-item-title class="white&#45;&#45;text" v-text="item.title"></v-list-item-title>-->
    <!--                    </v-list-item-content>-->
    <!--                </template>-->
    <!--                <template v-if="item.items.length > 0"-->
    <!--                >-->
    <!--                    <v-list-item-->
    <!--                            v-for="child in item.items"-->
    <!--                            :key="child.title"-->
    <!--                    >-->

    <!--                        <v-list-item-content>-->
    <!--                            <router-link :to="{name : child.to}">-->
    <!--                                <v-list-item-title class="white&#45;&#45;text" v-text="child.title"></v-list-item-title>-->
    <!--                            </router-link>-->
    <!--                        </v-list-item-content>-->
    <!--                    </v-list-item>-->
    <!--                </template>-->
    <!--            </v-list-group>-->
    <!--        </v-list>-->
  </v-navigation-drawer>
</template>

<script>
import {
  mdiHome,
  mdiHomeAnalytics,
  mdiInbox,
  mdiImageAlbum,
  mdiCalendar,
  mdiAccountSupervisor,
  mdiApplicationSettings,
  mdiSale,
  mdiInformation,
  mdiAccountGroup,
  mdiAccountStar,
  mdiContacts
} from "@mdi/js";
import { mapActions, mapState } from "vuex";

export default {
  name: "SideNav",
  data() {
    return {
      icon: {
        mdiHome,
        mdiHomeAnalytics,
        mdiInbox,
        mdiImageAlbum,
        mdiCalendar,
        mdiAccountSupervisor,
        mdiApplicationSettings,
        mdiSale,
        mdiAccountStar,
        mdiContacts
      },
      itemsForList: [
        {
          title: this.$t('sideNav.main'),
          icon: mdiHome,
          to: "main",
          role: ""
        },
        {
          title: 'الأصناف',
          to: 'categories',
          icon: mdiHomeAnalytics,
          role: ""
        },
        {
          title: 'الدورات',
          title: this.$t('sideNav.courses'),
          to: 'courses',
          icon: mdiHomeAnalytics,
          role: ""
        },
        {
          title: this.$t('sideNav.students'),
          icon: mdiSale,
          to: "students",
          role: ""
        },
        {
          title: this.$t('sideNav.instructors'),
          to: 'instructors',
          icon: mdiHomeAnalytics,
          role: ""
        },
        {
          title: this.$t('sideNav.certificates'),
          to: 'certificates',
          icon: mdiInbox,
          role: ""
        },
        {
          title: this.$t('sideNav.slider'),
          icon: mdiImageAlbum,
          to: 'sliders',
          role: ""
        },
        {
          title: this.$t('sideNav.partners'),
          icon: mdiAccountGroup,
          to: "partners",
          role: ""
        },
        {
          title: this.$t('sideNav.faqs'),
          icon: mdiAccountSupervisor,
          to: "faq",
          role: ""
        },
        {
          title: this.$t('sideNav.trainingPaths'),
          icon: mdiApplicationSettings,
          to: "Training path",
          role: ""
        },
        {
          title: this.$t('sideNav.messages'),
          icon: mdiApplicationSettings,
          to: "messages",
          role: ""
        },
        {
          title: this.$t('sideNav.aboutUs'),
          icon: mdiInformation,
          to: "EditAboutUs",
          role: ""
        },
        {
          title: this.$t('sideNav.admins'),
          icon: mdiContacts,
          to: "admins",
          role: ""
        },
        {
          title: 'اللجنة الأكاديمية',
          icon: mdiContacts,
          to: "editors",
          role: ""
        },
      ],
      itemsForEditor: [
        {
          title: this.$t('sideNav.main'),
          icon: mdiHome,
          to: "main",
          role: ""
        },
      ]
      // items: [
      //     {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'لوحة التحكم',
      //         to : 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'المبيعات',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'التقارير',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'احصائيات',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'صندوق البريد',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'وسائط',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'القهرس',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'المستخدمينم',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'الاعدادات',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     },
      // ],
    };
  },
  computed: {
    ...mapState(["DashBoard", "rtl"]),
    menuStatus() {
      return this.DashBoard.menuIsOpen;
    },
    isAdmin () {
      return (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal')
    },
  }
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}

    .theme--light.v-navigation-drawer {
        top: 0 !important;
    }

    .v-list-item__title {
        font-size: 18px !important;
    }

    .theme--light .v-icon {
        color: white !important;
    }
</style>
