<template>
  <v-app-bar
    color="white accent-4"
    elevation="1"
    dense
    app
    :shrink-on-scroll="false"
  >
    <v-app-bar-nav-icon @click.stop="changeMenuStatus()">
      <v-icon>{{this.menuIcon }}</v-icon>
    </v-app-bar-nav-icon>
    <div style="width: 35px;" class="me-2">
      <v-img :src="logo"></v-img>
    </div>
    <v-spacer></v-spacer>
    <div class="text-center">
      <v-menu transition="slide-y-transition" offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon class="mx-12" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <span>
                  <v-icon>{{icons.mdiAccountCircleOutline}}</v-icon>
                </span>
              </v-btn>
            </template>
            <span>الحساب</span>
          </v-tooltip>
        </template>
        <v-list>
          <!-- <v-list-item
            :to=" { name: 'home'}"
          >
            <v-list-item-title>
              <v-icon class="me-6">{{ icons.mdiAccountDetails }}</v-icon>
              الحساب
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="logoutMethod">
            <v-list-item-title>
              <v-icon class="me-6">{{ icons.mdiLogout }}</v-icon>
              تسجيل الخروج
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-progress-linear :active="false" indeterminate absolute top color="light-blue" height="3px"></v-progress-linear>
  </v-app-bar>

</template>

<script>
import {
  mdiWeb,
  mdiAccountCircleOutline,
  mdiLogout,
  mdiFormTextboxPassword,
  mdiAccountDetails,
  mdiAndroidMessages,
  mdiBell,
  mdiCommaBox,
  mdiMenu,
  mdiCheckBoxMultipleOutline,
  mdiInformationOutline,
  mdiViewList
} from "@mdi/js";
import { mapActions } from 'vuex';

export default {
  name: "TopNav",
  data() {
    return {
      logo: require("./../../../assets/images/LOGO.png"),
      icons: {
        mdiViewList,
        mdiWeb,
        mdiAccountCircleOutline,
        mdiLogout,
        mdiFormTextboxPassword,
        mdiAccountDetails,
        mdiAndroidMessages,
        mdiBell,
        mdiCommaBox,
        mdiMenu,
        mdiCheckBoxMultipleOutline,
        mdiInformationOutline
      }
    };
  },
  methods: {
    ...mapActions('AuthAdmin', ['logout']),
    logoutMethod() {
      if (localStorage.getItem('adminType') === 'editor'){
          this.$router.push('/dashboard/editorLogin');
      } else {
        this.$router.push('/dashboard/adminLogin');
      }
        this.logout();
    },
    changeMenuStatus(){
      return this.$store.dispatch('DashBoard/changeMenuStatus')
    }
  }
}
</script>

<style scoped>

</style>
